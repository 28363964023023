import copy from "copy-to-clipboard"
import React, { useState } from "react"
import Button from "../../components/elements/Button"
import { H1, H2 } from "../../components/elements/Headings"
import Input from "../../components/elements/Input"
import {
  ContentContainer,
  PageTitleContainer,
} from "../../components/layout/Containers"
import Layout from "../../components/layout/Layout"
import SEO from "../../components/seo"
import NotificationUtils from "../../utils/notification"

function DateToTimestamp() {
  const [date, setDate] = useState<Date>(null)
  const [timestamp, setTimestamp] = useState<number | null>(null)

  function convert() {
    if (date != null) setTimestamp(new Date().getTime())
  }

  function reset() {
    setDate(null)
    setTimestamp(null)
  }

  const copyToClipboard = () => {
    copy(`${timestamp}`)
    NotificationUtils.default({
      title: "Copied!",
      description: "Timestamp copied to your clipboard",
    })
  }

  function setDateString(dateString: string) {
    setDate(new Date(dateString.substr(0, 10)))
  }

  return (
    <>
      <SEO title="Date to Timestamp" />
      <Layout>
        <PageTitleContainer>
          <H1>Date to Timestamp</H1>
          <H2>Paste your date here and press the button!</H2>
        </PageTitleContainer>
        <ContentContainer className="flex justify-between">
          <Input
            className="w-64"
            placeholder="Date to convert..."
            onChange={$event => setDateString($event)}
            type="date"
            value={date ? date.toISOString().substr(0, 10) : ""}
          />
          <div className="flex">
            <Button click={convert} primary>
              Convert
            </Button>
            <Button
              click={() => {
                setDate(new Date())
                convert()
              }}
              secondary
            >
              Current Date
            </Button>
            <Button click={reset} accent>
              Reset
            </Button>
          </div>
        </ContentContainer>
        {/* {date ? date.toLocaleDateString() : ""} */}
        {timestamp && (
          <ContentContainer className="mt-8 flex">
            <div className="self-center">
              The timestamp is <strong>{timestamp}</strong>
            </div>
            <div>
              <Button accent click={copyToClipboard}>
                Copy to clipboard!
              </Button>
            </div>
          </ContentContainer>
        )}
      </Layout>
    </>
  )
}

export default DateToTimestamp
